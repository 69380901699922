import React from "react";
import { HFlow, Icon, Text, VFlow } from "bold-ui";

const teamCardStyle = {
  borderBottom: "1px solid #D3D4DD",
  height: "55px",
  display: "flex",
  alignItems: "center",
};

const TeamCard = ({ users, isVotingTime }) => {
  return (
    <VFlow vSpacing={0}>
      {users &&
        Object.entries(users).map(([user, { vote, imageUrl, userName }]) => {
          if (!user) return null;

          return (
            <div key={user} style={teamCardStyle}>
              <HFlow
                style={{
                  paddingLeft: "24px",
                  paddingRight: "42px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  width: "100%",
                }}
                alignItems="center"
                justifyContent="space-between"
              >
                <HFlow alignItems="center" justifyContent="space-between">
                  <img
                    src={imageUrl}
                    alt={userName}
                    width="40"
                    height="40"
                    style={{ borderRadius: "50%" }}
                  />
                  <Text fontWeight="bold"> {userName}</Text>
                </HFlow>
                {isVotingTime ? (
                  vote && (
                    <Icon size={1.5} icon="checkCircleFilled" fill="success" />
                  )
                ) : (
                  <Text fontWeight="bold">{vote}</Text>
                )}
              </HFlow>
            </div>
          );
        })}
    </VFlow>
  );
};

export default TeamCard;
