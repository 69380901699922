import React from "react";
import { HFlow, Heading, Link } from "bold-ui";
import { useUser } from "../context/UserContext";
import { useAdmin } from "../context/AdminContext";

import { useNavigate } from "react-router-dom";

const headerStyle = {
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  height: "55px",
  borderBottom: "1px solid #e0e0e0",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  position: "relative",
  zIndex: 1000,
};

// const logoBridgeStyle = {
//   marginLeft: "16px",
//   marginTop: "14.5px",
//   height: "26px",
//   width: "auto",
// };

const headingStyle = {
  marginTop: "17px",
  lineHeight: "1",
  marginLeft: "16px",
};

const Header = () => {
  const navigate = useNavigate();
  const { userProfile, logout } = useUser();
  const { isAdmin } = useAdmin();

  const handleHome = () => {
    navigate("/create");
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <header style={headerStyle}>
      <HFlow style={{ height: "55px" }} justifyContent="space-between">
        <HFlow>
          {/* <img style={logoBridgeStyle} src={logo} alt="Logo Bridge" /> */}
          <Heading fontSize={1.25} level={1} style={headingStyle}>
            b<span style={{ color: "#0069d0" }}>_</span>agile
          </Heading>
        </HFlow>
        <HFlow
          style={{ marginRight: "32px" }}
          alignItems="center"
          justifyContent="center"
        >
          {isAdmin && userProfile.email && (
            <Link style={{ color: "#333333" }} onClick={handleHome}>
              Home
            </Link>
          )}
          {userProfile.email && (
            <Link style={{ color: "#333333" }} onClick={handleLogout}>
              Sair
            </Link>
          )}
        </HFlow>
        {/* TODO: Add a toggle button for dark/light mode */}
      </HFlow>
    </header>
  );
};

export default Header;
