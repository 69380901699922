import { useState, useEffect } from "react";

export const useKeypressSequence = (targetSequence, callback) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === targetSequence[index]) {
        // Se o caractere pressionado for o próximo na sequência
        if (index === targetSequence.length - 1) {
          callback();
          setIndex(0); // Resetar o índice após encontrar a sequência alvo
        } else {
          setIndex((prevIndex) => prevIndex + 1);
        }
      } else {
        setIndex(0); // Reiniciar se a tecla pressionada não corresponder
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [index, targetSequence, callback]);
};
