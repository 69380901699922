import React from "react";
import { Text } from "bold-ui";
import "./VotingCardStyles.css";

const VotingCard = ({ value, selectedValue, onClick }) => {
  const isSelected = value === selectedValue;

  return (
    <div
      className={`outerDiv ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className={`innerDiv ${isSelected ? "selected" : ""}`}>
        <Text
          fontWeight={"bold"}
          style={isSelected ? { color: "#fff" } : { color: "#002451" }}
        >
          {value}
        </Text>
      </div>
    </div>
  );
};

export default VotingCard;
