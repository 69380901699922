import React from "react";
import { teamOptions } from "../../../assets/teams";
import { Text, VFlow, HFlow, Tooltip, Button, Icon } from "bold-ui";

const RoomInfo = ({
  users,
  isVotingTime,
  room,
  setIsNotSharing,
  isNotSharing,
  isAdmin,
}) => {
  const calculateMean = (users) => {
    if (!users) return "-";

    const votes = Object.values(users)
      .filter(({ vote }) => Boolean(vote))
      .map(({ vote }) => Number(vote.replace(",", ".")));
    const sum = votes.reduce((a, b) => a + b, 0);
    const mean = votes.length ? sum / votes.length : 0;

    if (Number.isInteger(mean)) {
      return mean.toString();
    } else {
      let meanStr = mean.toFixed(2).replace(".", ",");

      if (meanStr.endsWith("0")) {
        meanStr = meanStr.slice(0, -1);
      }
      return meanStr;
    }
  };

  const team = teamOptions.find((option) => option.value === room);

  return (
    <VFlow vSpacing={0.25} style={{ paddingLeft: "24px" }}>
      <HFlow alignItems="center">
        <Text fontWeight="bold" fontSize={1}>
          {team ? team.label : "Equipe não encontrada"}
        </Text>
        {isAdmin && (
          <Tooltip
            text={
              isNotSharing
                ? "Esconder visão do Scrum Master"
                : "Mostrar visão do Scrum Master"
            }
          >
            <Button
              size="small"
              onClick={() => {
                setIsNotSharing(!isNotSharing);
              }}
            >
              <Icon icon={isNotSharing ? "eyeFilled" : "eyeHiddenFilled"} />
            </Button>
          </Tooltip>
        )}
      </HFlow>
      <VFlow vSpacing={1.5}>
        <Text fontSize={0.875}>
          b<span style={{ color: "#0069d0" }}>_</span>agile
        </Text>
        {!isVotingTime && (
          <Text fontWeight={700} fontSize={1}>
            Média dos votos: {calculateMean(users)}
          </Text>
        )}
      </VFlow>
    </VFlow>
  );
};

export default RoomInfo;
