import { useUser } from "../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";

export function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userProfile } = useUser();

  const isAuthenticated = userProfile && userProfile.email;

  const checkAuthentication = () => {
    if (!isAuthenticated) {
      localStorage.setItem("attemptedURL", location.pathname);
      navigate("/");
    }
  };

  return { isAuthenticated, checkAuthentication };
}
