import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import socket from "../../socket";
import { useUser } from "../../context/UserContext";
import { useAdmin } from "../../context/AdminContext";
import { useAuth } from "../../hooks/useAuth";
import { Button, HFlow, Text, VFlow, Tooltip } from "bold-ui";
import { getPerguntasByNivel, getVotingOptions } from "../../assets/data";
import LevelSelect from "../../components/LevelSelect";
import ErrorRoom from "../../components/ErrorRoom";
import "./VoteRoomStyles.css";

import AppliedCategory from "../../components/AppliedCategory";
import {
  LevelTag,
  VotingCard,
  GradeCommentInput,
  ProgressBar,
  NavigationButtons,
  RoomInfo,
  TeamCard,
} from "./components";

function VotingRoom() {
  // resposta vinda do servidor
  const [response, setResponse] = useState({});
  // variavel que define se esta na hora de votar ou se os votos estao revelados (adm que controla)
  const [isVotingTime, setIsVotingTime] = useState();
  // variavel que salva a opcao de voto do usuario, importante para a estilização
  const [selectedValue, setSelectedValue] = useState();
  // campo de comentario (disponivel apenas para adm editar)
  const [commentField, setCommentField] = useState("");
  // campo de nota (disponivel apenas para adm editar)
  const [gradeField, setGradeField] = useState("");
  // indexes da categoria e do item
  const [currentIndexes, setCurrentIndexes] = useState({
    item: 0,
    category: 0,
  });
  // nome da sala
  const { room } = useParams();
  // dados do usuario
  const { userProfile } = useUser();
  // variavel que define se o usario é adm
  const { isAdmin, setIsAdmin } = useAdmin();
  // variavel para o campo de nota
  const [isNotValid, setIsNotValid] = useState(true);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [triggerNextCategory, setTriggerNextCategory] = useState(false);

  const [isNotSharing, setIsNotSharing] = useState(true);

  const [canGoNext, setCanGoNext] = useState(true);
  const [canGoPrevious, setCanGoPrevious] = useState(true);
  // variavel para salvar a lista de usuarios quando os votos forem revelados (evita problemas por conta de desconexoes)
  const [frozenUsers, setFrozenUsers] = useState({});

  // identifica se o usuario está autenticado
  const { checkAuthentication: checkAuth } = useAuth();
  const checkAuthentication = useCallback(() => {
    checkAuth();
  }, [checkAuth]);
  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  const userName = userProfile.name;
  const userEmail = userProfile.email;
  const userImage = userProfile.imageUrl;

  const currentCategoria =
    response.data?.[currentIndexes.item]?.categorias?.[currentIndexes.category];
  const currentNivel = currentCategoria?.nivelSelecionado;

  const prevIsVotingTimeRef = useRef();

  // Atualizar prevIsVotingTimeRef somente quando isVotingTime mudar
  useEffect(() => {
    prevIsVotingTimeRef.current = isVotingTime;
  }, [isVotingTime]);

  useEffect(() => {
    const updateUsersData = (currentItemIndex, currentCategoryIndex) => {
      // Emitir um evento de socket para solicitar os dados atualizados dos usuários
      socket.emit("requestUpdatedUsers", {
        currentItemIndex: currentItemIndex,
        currentCategoryIndex: currentCategoryIndex,
      });
    };

    const handleUpdatedUsers = (updatedUsers) => {
      setResponse((prevResponse) => ({
        ...prevResponse,
        users: updatedUsers,
      }));
      setFrozenUsers(updatedUsers);
    };

    const roomDataHandler = (newResponse) => {
      setResponse(newResponse);
      setCurrentIndexes({
        item: newResponse.currentItemIndex,
        category: newResponse.currentCategoryIndex,
      });

      if (newResponse.currentCategoryIndex !== undefined) {
        const currentItem = newResponse.data?.[newResponse.currentItemIndex];
        const currentCategory =
          currentItem?.categorias?.[newResponse.currentCategoryIndex];
        // if aplicado anteriormente
        if (currentCategory?.nota && newResponse.isVotingTime === null) {
          setIsVotingTime(false);
          // Chama a função para atualizar os dados dos usuários
          updateUsersData(
            newResponse.currentItemIndex,
            newResponse.currentCategoryIndex
          );
          setGradeField(currentCategory?.nota);
          setCommentField(currentCategory?.comentario || "");
        } else {
          // if nunca foi aplicado
          if (newResponse.isVotingTime === null) {
            setIsVotingTime(true);
          } else {
            if (
              newResponse.isVotingTime === false &&
              prevIsVotingTimeRef.current === true
            ) {
              setFrozenUsers(newResponse.users);
            }
            setIsVotingTime(newResponse.isVotingTime);
          }
        }
      }

      setIsAdmin(newResponse.admin === userProfile.email);

      const usersArray = Object.entries(newResponse.users);

      // Verificando se userEmail não está na lista de usuários
      const isUserInList = usersArray.some(([email, _]) => email === userEmail);

      if (!isUserInList) {
        console.log("joinRoom tardio");
        socket.emit("joinRoom", { userName, userImage, userEmail, room });
      }
    };

    const votesClearedHandler = () => {
      setSelectedValue(null);
    };

    socket.emit("joinRoom", { userName, userImage, userEmail, room });

    socket.on("roomData", roomDataHandler);
    socket.on("votesCleared", votesClearedHandler);
    socket.on("updatedUsers", handleUpdatedUsers);

    return () => {
      socket.off("roomData", roomDataHandler);
      socket.off("votesCleared", votesClearedHandler);
      socket.off("updatedUsers", handleUpdatedUsers);
    };
  }, [userName, userImage, userEmail, room, userProfile, setIsAdmin]);

  const canGoToNext = useCallback(
    (indexes) => {
      if (!response.data || !response.data[indexes.item]) return false;

      const currentItem = response.data[indexes.item];
      if (currentItem.categorias.length - 1 > indexes.category) {
        return true;
      } else if (response.data.length - 1 > indexes.item) {
        return true;
      }
      return false;
    },
    [response.data]
  );

  const canGoToPrevious = useCallback(
    (indexes) => {
      if (!response.data || !response.data[indexes.item]) return false;

      if (indexes.category > 0) {
        return true;
      } else if (indexes.item > 0) {
        return true;
      }
      return false;
    },
    [response.data]
  );

  useEffect(() => {
    if (currentIndexes) {
      setCanGoNext(canGoToNext(currentIndexes));
      setCanGoPrevious(canGoToPrevious(currentIndexes));
    }
  }, [canGoToNext, canGoToPrevious, currentIndexes]);

  // atualiza todos os usuarios dos dados da sala
  const updateRoom = (updatedData, itemIndex, categoryIndex) => {
    socket.emit("updateRoom", {
      room,
      data: updatedData,
      currentItemIndex: itemIndex,
      currentCategoryIndex: categoryIndex,
    });
  };

  const hideVotes = () => {
    socket.emit("hideVotes");
  };

  const handleNivelCategoryChange = (value) => {
    clearVotes();
    socket.emit("storeLevel", {
      room: room,
      itemIndex: currentIndexes.item,
      categoryIndex: currentIndexes.category,
      level: value,
    });

    setResponse((prevResponse) => {
      const newData = [...prevResponse.data];
      newData[currentIndexes.item].categorias[
        currentIndexes.category
      ].nivelSelecionado = value;

      // Chamar updateRoom com os dados atualizados diretamente
      // TODO - refatorar pois não precisa passar o objeto todo, fazer um evento especifico
      updateRoom(newData, currentIndexes.item, currentIndexes.category);

      // provavelmente nao precisa desse return
      return { ...prevResponse, data: newData };
    });
  };

  // envia o voto do usuario
  const sendEstimate = (estimate) => {
    if (selectedValue === estimate) {
      setSelectedValue(null);
      socket.emit("setEstimate", { userEmail, estimate: null, room });
    } else {
      setSelectedValue(estimate);
      socket.emit("setEstimate", { userEmail, estimate, room });
    }
  };

  // avança nos indexes
  const nextCategory = () => {
    if (
      showConfirmation &&
      !window.confirm(
        "Você tem certeza que gostaria de mudar de categoria sem salvar?"
      )
    ) {
      return;
    }
    setShowConfirmation(false);

    let nextIndexes = { ...currentIndexes };
    const currentItem = response.data[nextIndexes.item];

    if (currentItem.categorias.length - 1 > nextIndexes.category) {
      nextIndexes.category += 1;
    } else if (response.data.length - 1 > nextIndexes.item) {
      nextIndexes.item += 1;
      nextIndexes.category = 0;
    }
    resetVotingAndUpdate(response.data, nextIndexes.item, nextIndexes.category);
  };

  // retorna nos indexes
  const previousCategory = () => {
    if (
      showConfirmation &&
      !window.confirm(
        "Você tem certeza que gostaria de mudar de categoria sem salvar?"
      )
    ) {
      return;
    }
    setShowConfirmation(false);

    let prevIndexes = { ...currentIndexes };

    if (prevIndexes.category > 0) {
      prevIndexes.category -= 1;
    } else if (prevIndexes.item > 0) {
      prevIndexes.item -= 1;
      prevIndexes.category =
        response.data[prevIndexes.item].categorias.length - 1;
    }

    resetVotingAndUpdate(response.data, prevIndexes.item, prevIndexes.category);
  };

  // revela os votos
  const showVotes = () => {
    socket.emit("showVotes");
  };

  useEffect(() => {
    // Configurar o ouvinte para o evento 'setCommentField'
    const handleCommentUpdate = (data) => {
      setCommentField(data.value); // Atualiza o estado com o valor recebido
    };

    // Configurar o ouvinte para o evento 'setGradeField'
    const handleGradeUpdate = (data) => {
      setGradeField(data.value); // Atualiza o estado com o valor recebido
    };

    // Aqui estamos escutando o evento 'setCommentField'
    socket.on("updateCommentField", handleCommentUpdate);

    // Aqui estamos escutando o evento 'setGradeField'
    socket.on("updateGradeField", handleGradeUpdate);

    // Limpeza: Remove os ouvintes quando o componente for desmontado
    return () => {
      socket.off("updateCommentField", handleCommentUpdate);
      socket.off("updateGradeField", handleGradeUpdate);
    };
  }, []); // Array de dependências vazio significa que isso vai rodar apenas uma vez, quando o componente montar

  // mantem a variavel do comentario atualizada com o input
  const handleCommentChange = (e) => {
    setShowConfirmation(true);
    let value = e.target.value;
    setCommentField(value);
    socket.emit("setCommentField", { value });
  };

  // mantem a variavel da nota atualizada com o input
  const handleGradeChange = (e) => {
    setShowConfirmation(true);
    let value = e.target.value;
    setGradeField(value);
    socket.emit("setGradeField", { value });
  };

  const resetFields = () => {
    setIsNotValid(true);
    const emptyString = "";
    socket.emit("setCommentField", { emptyString });
    socket.emit("setGradeField", { emptyString });
  };

  // limpa os votos de todos os usuarios
  const clearVotes = () => {
    socket.emit("clearVotes");
  };

  const clearVotesAndUpdate = (updatedData, itemIndex, categoryIndex) => {
    socket.emit("clearVotesAndUpdate", {
      data: updatedData,
      itemIndex,
      categoryIndex,
    });
  };

  // chama os metodos nessarios para salvar e continuar com a votação
  const saveCategoriaResults = () => {
    setShowConfirmation(false);
    setTriggerNextCategory(true); // Sinaliza para chamar nextCategory
    storeResults();
  };

  useEffect(() => {
    if (triggerNextCategory && !showConfirmation) {
      nextCategory();
      setTriggerNextCategory(false); // Reseta o sinalizador
    }
    // TODO fazer algo sobre esse warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNextCategory, showConfirmation]);

  // reinicia a votação ao mudar de categora
  const resetVotingAndUpdate = (updatedData, itemIndex, categoryIndex) => {
    resetFields();
    clearVotesAndUpdate(updatedData, itemIndex, categoryIndex);
  };

  // reinicia a votação da categoria atual
  const restartVoting = () => {
    hideVotes();
    clearVotes();
  };

  // salva os dados
  const storeResults = () => {
    currentCategoria.comentario = commentField;
    currentCategoria.nota = gradeField;

    socket.emit("storeResults", {
      room,
      comment: commentField,
      grade: gradeField,
      users: frozenUsers,
    });
  };

  return response.data && response.data.length > 0 ? (
    <div className="outerDivStyle">
      <div className="questionsDivStyle">
        <VFlow>
          {isAdmin && isNotSharing && (
            <NavigationButtons
              nextCategory={nextCategory}
              previousCategory={previousCategory}
              canGoNext={canGoNext}
              canGoPrevious={canGoPrevious}
              canChange={isNotValid}
            />
          )}
          <div style={{ marginTop: "-10px" }}>
            <ProgressBar response={response} currentIndexes={currentIndexes} />
          </div>
          <VFlow vSpacing={0.5}>
            <VFlow vSpacing={0}>
              <Text
                fontWeight={700}
                fontSize={1.5}
                style={{ color: "#002451" }}
              >
                {response.data[currentIndexes.item].nome}
              </Text>
              <HFlow>
                <Text fontSize={1.25}>{currentCategoria.nome}</Text>
                {currentCategoria.nota && <AppliedCategory></AppliedCategory>}
              </HFlow>
            </VFlow>

            <HFlow>
              {isAdmin && isNotSharing ? (
                <LevelSelect
                  currentNivel={currentNivel}
                  onChange={(newValue) => handleNivelCategoryChange(newValue)}
                  hideAdvanced={
                    response.data[currentIndexes.item]?.categorias?.[
                      currentIndexes.category
                    ].nivelAvancado.length === 0
                  }
                />
              ) : (
                <LevelTag value={currentNivel} />
              )}
            </HFlow>
          </VFlow>
          <ul style={{ paddingInlineStart: "24px", margin: "0px" }}>
            {getPerguntasByNivel(currentCategoria).map((item, index) => (
              <li key={index}>
                <Text fontSize={1}>{item}</Text>
              </li>
            ))}
          </ul>
        </VFlow>
        {isVotingTime ? (
          isNotSharing && (
            <HFlow
              style={{ flexWrap: "wrap", display: "flex" }}
              hSpacing={0.5}
              justifyContent="center"
            >
              {getVotingOptions(currentNivel).map((value, index) => (
                <VotingCard
                  key={index}
                  selectedValue={selectedValue}
                  value={value}
                  onClick={() => sendEstimate(value)}
                />
              ))}
            </HFlow>
          )
        ) : (
          <VFlow>
            <GradeCommentInput
              grade={gradeField}
              comment={commentField}
              handleGradeChange={handleGradeChange}
              handleCommentChange={handleCommentChange}
              setIsNotValid={setIsNotValid}
              level={currentCategoria.nivelSelecionado}
              isDisabled={!isAdmin || !isNotSharing}
            />
            {isAdmin && isNotSharing && (
              <Tooltip text={isNotValid && "Campo nota inválido"}>
                <Button
                  size="small"
                  kind="primary"
                  disabled={isNotValid}
                  onClick={() => saveCategoriaResults()}
                >
                  Salvar e continuar
                </Button>
              </Tooltip>
            )}
          </VFlow>
        )}
      </div>
      <div className="teamDivStyle">
        <VFlow vSpacing={1.5}>
          <RoomInfo
            users={isVotingTime ? response.users : frozenUsers}
            isVotingTime={isVotingTime}
            room={room}
            setIsNotSharing={setIsNotSharing}
            isNotSharing={isNotSharing}
            isAdmin={isAdmin}
          />
          <TeamCard
            users={isVotingTime ? response.users : frozenUsers}
            isVotingTime={isVotingTime}
          />
        </VFlow>
        {isAdmin && isNotSharing && (
          <div className="buttonContainerStyle">
            {isVotingTime ? (
              <Button size="small" kind="primary" onClick={() => showVotes()}>
                Revelar votos
              </Button>
            ) : (
              <Button
                size="small"
                kind="normal"
                style={{ marginRight: "16px" }}
                onClick={() => restartVoting()}
              >
                Refazer votação
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <ErrorRoom
      title="Aguarde o início da aplicação"
      paragraph="Verifique com o seu Scrum Master se o link está correto"
    ></ErrorRoom>
  );
}

export default VotingRoom;
