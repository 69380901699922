import React from "react";

import { Nivel } from "../assets/data";

const levelOptions = [
  {
    label: "básico",
    value: Nivel.BASICO,
  },
  {
    label: "intermediário",
    value: Nivel.INTERMEDIARIO,
  },
  {
    label: "avançado",
    value: Nivel.AVANCADO,
  },
];
const LevelSelect = ({ onChange, currentNivel, hideAdvanced }) => {
  const handleChange = (e) => {
    const selectedValue = e.target.value;

    if (onChange) {
      onChange(selectedValue);
    }
  };

  const getColor = (value) => {
    switch (value) {
      case Nivel.BASICO:
        return "#498FFF";
      case Nivel.INTERMEDIARIO:
        return "#E76F00";
      case Nivel.AVANCADO:
        return "#8600B6";
      default:
        return "black";
    }
  };

  const getWidth = (value) => {
    switch (value) {
      case Nivel.BASICO:
        return "117px";
      case Nivel.INTERMEDIARIO:
        return "163px";
      case Nivel.AVANCADO:
        return "136px";
      default:
        return "";
    }
  };

  return (
    <div>
      <select
        value={currentNivel || Nivel.BASICO} // use diretamente o currentNivel
        onChange={handleChange}
        style={{
          paddingTop: "2px",
          paddingLeft: "8px",
          paddingBottom: "2px",
          borderRadius: "12px",
          border: "1px solid",
          borderRightWidth: "6px",
          borderColor: getColor(currentNivel || Nivel.BASICO),
          color: "#fff",
          backgroundColor: getColor(currentNivel || Nivel.BASICO),
          fontWeight: "bold",
          width: getWidth(currentNivel || Nivel.BASICO),
          outline: "none",
          boxShadow: "none",
        }}
      >
        {levelOptions
          .filter(
            (option) => !(hideAdvanced && option.value === Nivel.AVANCADO)
          ) // Filtra a opção avançado
          .map((option) => (
            <option
              key={option.value}
              value={option.value}
              style={{
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              }}
            >
              Nível {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};
export default LevelSelect;
