import React, { useState, useEffect } from "react";
import { Progress } from "bold-ui";

const progressBarStyle = {
  height: "8px",
  "@media (max-width: 599px)": {
    display: "none",
  },
};

const ProgressBar = ({ response, currentIndexes }) => {
  const [maxValue, setMaxValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    // Calculate the max value
    const total = response.data?.reduce(
      (acc, item) => acc + item.categorias.length,
      0
    );
    setMaxValue(total - 1);

    // Calculate the current value
    let currentTotal = 0;
    for (let i = 0; i < currentIndexes.item; i++) {
      currentTotal += response.data[i].categorias.length;
    }
    currentTotal += currentIndexes.category;
    setCurrentValue(currentTotal);
  }, [response.data, currentIndexes]);

  return (
    <Progress
      style={progressBarStyle}
      value={currentValue}
      max={maxValue}
      type="primary"
    />
  );
};

export default ProgressBar;
