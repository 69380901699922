import React, { useState, useEffect, useContext, createContext } from "react";

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(() => {
    const savedProfile = localStorage.getItem("userProfile");
    return savedProfile
      ? JSON.parse(savedProfile)
      : {
          name: "",
          email: "",
          imageUrl: "",
        };
  });

  useEffect(() => {
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
  }, [userProfile]);

  const logout = () => {
    localStorage.removeItem("userProfile");
    setUserProfile({
      name: "",
      email: "",
      imageUrl: "",
    });
  };

  return (
    <UserContext.Provider value={{ userProfile, setUserProfile, logout }}>
      {children}
    </UserContext.Provider>
  );
};
