import { Heading, VFlow, Text } from "bold-ui";
import React from "react";
import errorImage from "../assets/errorImage.png";

const ErrorRoom = ({ title, paragraph }) => {
  const style = {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0",
    margin: "0px",
    height: "100%",
    width: "100%",
    textAlign: "center",
  };

  return (
    <VFlow style={style}>
      <Heading level={1} style={{ margin: "0px", color: "#002451" }}>
        {title}
      </Heading>
      <Text fontSize="0.875">{paragraph}</Text>
      <img
        width={"275"}
        height={"auto"}
        src={errorImage}
        alt="Imagem de erro"
      />
    </VFlow>
  );
};

export default ErrorRoom;
