import React from "react";

import { HFlow, Icon, Text } from "bold-ui";

const AppliedCategory = () => {
  return (
    <HFlow alignItems="center" hSpacing={0.5}>
      <Icon size={1} icon="checkCircleFilled" fill="success" />
      <Text color="success" fontWeight="bold">
        Categoria aplicada
      </Text>
    </HFlow>
  );
};

export default AppliedCategory;
