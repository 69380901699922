interface Categoria {
  nome: string;
  nota: string | null; // string por conta dos numeros com virgula
  comentario: string | null;
  nivelSelecionado: Nivel;
  nivelBasico: string[];
  nivelIntermediario: string[];
  nivelAvancado: string[];
}

export interface Item {
  nome: string;
  categorias: Categoria[];
}

export enum Nivel {
  BASICO = "basico",
  INTERMEDIARIO = "intermediario",
  AVANCADO = "avancado",
}

export function getPerguntasByNivel(categoria: Categoria) {
  switch (categoria.nivelSelecionado) {
    case Nivel.BASICO:
      return categoria.nivelBasico;
    case Nivel.INTERMEDIARIO:
      return categoria.nivelIntermediario;
    case Nivel.AVANCADO:
      return categoria.nivelAvancado;
  }
}

export function getVotingOptions(nivel: string) {
  switch (nivel) {
    case Nivel.BASICO:
      return [
        "1,0",
        "1,25",
        "1,50",
        "1,75",
        "2,0",
        "2,25",
        "2,50",
        "2,75",
        "3,0",
        "3,25",
        "3,50",
        "3,75",
        "4,0",
        "4,25",
        "4,50",
        "4,75",
        "5,0",
      ];
    case Nivel.INTERMEDIARIO:
      return [
        "5,25",
        "5,50",
        "5,75",
        "6,0",
        "6,25",
        "6,50",
        "6,75",
        "7,0",
        "7,25",
        "7,50",
        "7,75",
        "8,0",
      ];
    case Nivel.AVANCADO:
      return ["8,25", "8,50", "8,75", "9,0", "9,25", "9,50", "9,75", "10,0"];
    default:
      return [];
  }
}

export const bAgile: Item[] = [
  {
    nome: "Segurança como um pré-requisito",
    categorias: [
      {
        nome: "Clean Code e código sustentável",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe segue as diretrizes do projeto para configuração e uso de ferramentas de inspeção de código?",
          "A equipe se preocupa em obedecer a estrutura interna do código da aplicação?",
          "A equipe se preocupa em escrever código organizado, performático e legível?",
          "A equipe realiza Code Review internamente?",
        ],
        nivelIntermediario: [
          "(B) A equipe segue as diretrizes do projeto para configuração e uso de ferramentas de inspeção de código?",

          "Ao encontrar código que foge das práticas internas adotadas é realizada alguma ação (como: encaminhar para a equipe responsável, refatorar o código, etc)?",
          "A equipe é crítica e propõe melhorias em relação a estrutura interna do código da aplicação?",
          "No code review externo e interno, a equipe analisa se o código está organizado, performático e legível?",
          "Ao criar novos padrões de códigos e funções utilitárias, a equipe se preocupa em compartilhar isso com as outras equipes?",
          "A equipe possui o indicador do Tipo de esforço na categoria Dívida Técnica dentro do esperado pelo projeto?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Testes automatizados",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe reserva tempo na sprint para construção de testes automatizados?",
          "A construção dos testes automatizados visa a qualidade dos testes e não apenas a cobertura do código?",
          "No Code Review interno há preocupação em validar se os padrões de código foram seguidos nos testes escritos?",
          "Há evolução dos testes automatizados quando erros são descobertos para garantir que esses erros não ocorram novamente (garantir teste de regressão dessa funcionalidade)? ",
        ],
        nivelIntermediario: [
          "(B) A construção dos testes automatizados visa a qualidade dos testes e não apenas a cobertura do código?",
          "(B) Há evolução dos testes automatizados quando erros são descobertos para garantir que esses erros não ocorram novamente (garantir teste de regressão dessa funcionalidade)? ",

          "A equipe reserva tempo no planejamento para construção de testes automatizados e consegue entregá-los juntos com a demanda?",
          "No Code Review interno há preocupação em validar os cenários e se os padrões de código foram seguidos nos testes escritos?",
          "No Code Review externo há preocupação em validar se os padrões de código foram seguidos nos testes escritos?",
          "O time de desenvolvimento busca utilizar testes automatizados para facilitar o desenvolvimento? (por exemplo: durante o desenvolvimento são construídos testes que validem se a funcionalidade está correta e são aplicados conceitos de TDD)",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Qualidade E2E",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "(B) A equipe se preocupa se o tempo de resposta para realizar uma atividade/ação é adequado? ",

          "As funcionalidades são testadas simulando o mais próximo do cenário real (em volume de dados)?",
          "As últimas entregas para a equipe de qualidade foram realizadas com poucos bugs críticos?",
          "As últimas entregas para produção foram realizadas sem bugs críticos?",
        ],
        nivelIntermediario: [
          "(B) A equipe se preocupa se o tempo de resposta para realizar uma atividade/ação no sistema é adequado?",
          "(B) As últimas entregas para produção foram realizadas sem bugs críticos?",

          "As funcionalidades são testadas simulando o mais próximo do cenário real (em volume de dados e de requisições)?",
          "As últimas entregas para a equipe de qualidade foram realizadas com quase nenhum bug crítico?",
          "A equipe possui o indicador do Tipo de esforço na categoria Correção dentro do esperado pelo projeto?",
          "A equipe monitora e busca otimizar o lead time de bugs originados em ambiente de produção?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Segurança de dados",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe busca falhas de segurança durante a execução? (por exemplo: testes de vulnerabilidade, testes de permissões dos usuários, validações)",
          "A equipe avalia se é necessário garantir a rastreabilidade de manipulação dos dados dentro do sistema? (por exemplo: trilhas de auditoria, etc)",
          "A equipe busca armazenar e exibir apenas as informações necessárias para a demanda que está desenvolvendo?",
        ],
        nivelIntermediario: [
          "(B) A equipe busca armazenar e exibir apenas as informações necessárias para a demanda que está desenvolvendo?",

          "A equipe busca falhas de segurança durante a execução? (por exemplo: testes de vulnerabilidade, testes de permissões dos usuários, validações, falhas e controles complexos na API, etc)",
          "A equipe avalia se é necessário garantir a rastreabilidade de manipulação dos dados dentro do sistema ou via API? (por exemplo: trilhas de auditoria)",
          "A equipe conhece e leva em consideração as diretrizes da LGPD?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Práticas DevOps",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Todos do time possuem conhecimento suficiente para trabalhar com o Git no dia-a-dia? (por exemplo: organização de branches, clone, pull, push, merge e stash, etc)",
          "Todos do time possuem conhecimento suficiente para subir a aplicação localmente?",
          "Todo o time de desenvolvimento sabe identificar erros no pipeline do pull request/merge do projeto?",
          "Pelo menos um membro do time sabe identificar e acompanhar erros e métricas de desempenho nas ferramentas de monitoramento do projeto?",
        ],
        nivelIntermediario: [
          "(B) Todo o time de desenvolvimento sabe identificar erros no pipeline do pull request/merge do projeto?",

          "Todos do time possuem autonomia e confiança para trabalhar com o Git no dia-a-dia? (por exemplo: organização de branches, clone, pull, push, merge e stash, etc)",
          "Todo o time de desenvolvimento possui conhecimentos avançados para trabalhar com o Git no dia-a-dia? (rebase, cherry-pick)",
          "Todos do time possuem conhecimento suficiente para subir a aplicação localmente, identificar e resolver erros mais simples nesse processo? (por exemplo: erro no banco por estar em estado inválido, etc)",
          "Todo o time de desenvolvimento consegue otimizar seu fluxo de trabalho com docker? (gerenciamento de imagens, commit de imagens com cenários específicos, etc)",
          "Todo o time de desenvolvimento sabe identificar e acompanhar erros e métricas de desempenho nas ferramentas de monitoramento do projeto?",
          "Todo o time de desenvolvimento entende claramente como as ferramentas de gerenciamento de dependencias funcionam e estão organizadas?(por exemplo: ciclo de vida, diferença entre os comandos, etc)",
        ],
        nivelAvancado: [],
      },
    ],
  },
  {
    nome: "Experimente e aprenda rápido",
    categorias: [
      {
        nome: "Práticas Lean-Agile",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Possui gestão visual acessível do board da equipe?",
          "A equipe possui o documento de políticas de gestão do board?",
          "Todos os membros do time conhecem os objetivos de cada cerimônia realizada?",
          "A equipe considera que as cerimônias realizadas são úteis e coerentes com as suas necessidades?",
          "A equipe consegue encaminhar as tarefas não planejadas*? * não planejadas - demandas externas e internas que surgem durante o desenvolvimento da sprint",
        ],
        nivelIntermediario: [
          "As políticas de gestão do board estão simples e claras?",
          "A equipe conhece e segue as políticas de gestão do board da equipe?",
          "A equipe sente que as cerimônias são feitas da melhor maneira possível em relação a tempo, escopo e dinâmica?",
          "A equipe consegue encaminhar as tarefas não planejadas* sem comprometer as tarefas planejadas da sprint? * não planejadas - demandas externas que surgem durante o desenvolvimento da sprint",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Métricas ",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Todos os membros do time conhecem as métricas formalizadas pelo laboratório?",
          "O time faz o  acompanhamento das métricas?",
          "As decisões e o planejamento são com base nas métricas? ",
          "As métricas utilizadas pelo time seguem as métricas formalizadas pelo laboratório?",
        ],
        nivelIntermediario: [
          "(B) Todos os membros do time conhecem as métricas formalizadas pelo laboratório?",
          "(B) As métricas utilizadas pelo time seguem as métricas formalizadas pelo laboratório?",

          "A equipe se preocupa em evoluir seu uso de métricas? (por exemplo: evoluir métricas existentes, criar novas métricas e se necessário descontinuar métricas existentes, etc)",
          "A equipe considera que as métricas coletadas são úteis e coerentes com as suas necessidades?",
          "Na ausência do SM, a equipe consegue gerar as métricas?",
          "As métricas são coletadas de maneira automática?",
          " A equipe usa e acompanha o indicador do tipo de esforço?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Comprometimento com o produto (entregas)",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Existe um sentimento de responsabilidade compartilhada na equipe ao garantir a qualidade das entregas? ",
          "O time está comprometido com os prazos? ",
          "O time acompanha e atua em correções e melhorias prioritárias do produto após sua finalização? (por exemplo: PO entra na planilha de satisfação e acompanha a entrega?)  ",
          "As entregas da equipe são bem recebidas pelos usuários finais? (por exemplo: poucos feedbacks negativos, dúvidas em relação ao uso da funcionalidade, bugs, etc)",
        ],
        nivelIntermediario: [
          "(B) Existe um sentimento de responsabilidade compartilhada na equipe ao garantir a qualidade das entregas? ",
          "(B) O time está comprometido com os prazos? ",

          "O time acompanha e atua em correções e melhorias prioritárias do produto após sua finalização? (por exemplo: acompanhamento da satisfação do usuário, estatísticas do grafana/métricas de desempenho, etc) ",
          "As entregas da equipe são bem recebidas pelos usuários finais e pela equipe de qualidade? (por exemplo: poucos feedbacks negativos, dúvidas em relação ao uso da funcionalidade, bugs, etc)",
          "O time se sente motivado e engajado para levantar e apresentar argumentos para o cliente buscando o que é melhor para os usuários do sistema?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Compartilhamento de conhecimento",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time promove a troca de conhecimentos de forma contínua no dia a dia? (por exemplo: capacitações internas, pair-programming, etc)",
          "A equipe mostra as evoluções das funcionalidades para os bridgers mensalmente? ",
        ],
        nivelIntermediario: [
          "(B) O time promove a troca de conhecimentos de forma contínua no dia a dia? (por exemplo: capacitações internas, pair-programming, etc)",
          "(B) A equipe mostra as evoluções das funcionalidades para os bridgers mensalmente? ",

          "O time compartilha conhecimento com outros times? (por exemplo: Compartilhar dá +XP, através do slack com artigos, forum, evoluções técnicas, treinamentos entre equipes, troca de experiências entre POs/SMs/TLs, etc)?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Ritmo das Sprints",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe acompanha as issues para que o desenvolvimento ocorra como planejado? (por exemplo: monitora tempo da issue em cada etapa do processo, se necessário oferece ajuda para o responsável pela issue, etc)",
          "A equipe toma ações quando necessário se as issues atrasam? (por exemplo: busca auxílio de outras equipes, realiza replanejamento e/ou repriorização, etc)",
          "A equipe possui 50% das sprints concluídas no ciclo com o aproveitamento do esforço planejado dentro do esperado pelo projeto?",
          "A equipe discute e categoriza o motivo de atrasos das issues?",
        ],
        nivelIntermediario: [
          "A equipe acompanha riscos de atraso e toma ações com antecedência?",
          "A equipe elabora planos de ação para minimizar os motivos que geram atraso nas issues?",
          "A equipe possui 70% das sprints concluídas no ciclo com o aproveitamento do esforço planejado dentro do esperado pelo projeto?",
          "A equipe possui consistências nas suas entregas: coeficiente de variação da taxa de eficiência abaixo do esperado pelo projeto?",
          "A equipe toma decisões através do uso do gráfico de burndown?",
        ],
        nivelAvancado: [],
      },
    ],
  },
  {
    nome: "Valor a todo instante",
    categorias: [
      {
        nome: "Issues e Épicos",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Quando são incluidas no Sprint Backlog, as tarefas possuem as informações necessárias para o desenvolvimento descritas ou referenciadas? (por exemplo: critérios de aceitação/regras, contexto, protótipo, objetivo/descrição, etc)",
          "As issues épicas estão sempre atualizadas, organizadas e de fácil entendimento*? (escopo, descrição, issues relacionadas, análise de impacto, etc)",
          "As issues são claras para todos os membros da equipe?",
          "A equipe mantém o milestone das issues atualizado e compatível com o tempo necessário para desenvolvimento e estabilização da demanda, incluindo validação da equipe de qualidade externa quando necessária? ",
          "A equipe se preocupa em garantir que as issues épicas concluídas estão compatíveis com o que foi desenvolvido? (por exemplo: o escopo descrito, links para protótipos, milestone que foi entregue, etc)",
        ],
        nivelIntermediario: [
          "(B) Quando são incluidas no Sprint Backlog, as tarefas possuem as informações necessárias para o desenvolvimento descritas ou referenciadas? (por exemplo: critérios de aceitação/regras, contexto, protótipo, objetivo/descrição, etc)",
          "(B) A equipe se preocupa em garantir que as  issues épicas concluídas estão compatíveis com o que foi desenvolvido? (por exemplo: o escopo descrito, links para protótipos, milestone que foi entregue, etc)",
          "(B) As issues épicas estão sempre atualizadas, organizadas e de fácil entendimento*? (*escopo, descrição, issues relacionadas, análise de impacto, etc)",

          "As issues criadas pela equipe seguem um padrão de descrição e estrutura?",
          "A equipe se preocupa em descrever issues que fiquem claras para todos os membros do projeto?",
          "Todas as issues que são incluídas na sprint seguem o padrão de DoR (Definition of Ready) definido pela equipe?",
          "A equipe é proativa na definição da milestone das issues, além de mantê-la atualizada e compatível com o tempo necessário para desenvolvimento e estabilização da demanda, incluindo validação da equipe de qualidade externa quando necessária? ",
        ],
        nivelAvancado: [],
      },
      {
        nome: "User eXperience",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe discute e se envolve na etapa de descoberta do produto? ",
          "A equipe sabe delimitar as ideias e implementá-las, alinhando as necessidades do usuário com as regras e restrições de negócio definidas pelo cliente?",
          "A equipe utiliza as etapas do processo de b_thinking em suas demandas?",
          "A equipe utiliza os métodos e ferramentas propostos no b_thinking em suas demandas?",
          "A equipe discute e realiza implementações visando acessibilidade da demanda que está desenvolvendo? (por exemplo: tab order, contraste, fontes, quantidade de ações para atingir o objetivo final, adição de hints explicativos, etc)",
          "A equipe se preocupa em como será a experiência do usuário com evoluções do produto e novas funcionalidades? (por exemplo: como será o primeiro acesso, se a interface é intuitiva)",
        ],
        nivelIntermediario: [
          "Toda a equipe discute e se envolve nas etapas de descoberta do produto?",
          "A equipe consegue argumentar com o cliente em relação às regras e restrições de negócio, para garantir uma melhor experiência para o usuário?",
          "A equipe tem clareza de quais são as principais etapas do processo do b_thinking e consegue adaptá-lo de acordo com a demanda? ",
          "A equipe tem domínio dos métodos e ferramentas do b_thinking e sabe quais são mais indicados para cada necessidade?",
          "A equipe discute, realiza implementações e tem processos estruturados para garantir acessibilidade da demanda que está desenvolvendo? (por exemplo: tab order, contraste, fontes, quantidade de ações para atingir o objetivo final, adição de hints explicativos)",
          "A equipe se preocupa e valida em testes de usabilidade como será a experiência do usuário com evoluções do produto novas funcionalidades? (por exemplo: como será o primeiro acesso, se a interface é intuitiva)",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Entrega frequente",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe discute e define MVP das funcionalidades?",
          "A equipe inclui tarefas de melhoria no seu backlog e consegue desenvolver essas tarefas em um ritmo compatível com o esperado pelo projeto?",
          "A equipe se preocupa em concluir entregas funcionais antes de iniciar as próximas? (por exemplo: terminam front de uma funcionalidade antes de iniciar outras issues de back-end de outras funcionalidades, etc)",
        ],
        nivelIntermediario: [
          "A equipe consegue implementar MVPs de forma a fazer entregas funcionais menores e consequentemente mais frequentes?",
          "A equipe inclui tarefas de melhoria no seu backlog, tendo o indicador Tipo de esforço na categoria Evolução Bridge dentro do esperado pelo projeto?",
          "A equipe consegue realizar entregas incrementais para o cliente independente de restrições no lançamento dessas funcionalidades para produção?",
          "A equipe possui o indicador do Tipo de esforço na categoria Evolução (TED) dentro do esperado pelo projeto?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Valor de negócio",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Está claro para toda a equipe a lista de prioridades, incluindo issues relacionadas aos épicos, manutenções evolutivas e corretivas?",
          "As tarefas oriundas de monitoramentos e/ou corretivas são priorizadas de maneira eficaz?",
          "Há critérios de aceitação (requisitos) e estes são conferidos pelo time após a conclusão de cada épico?",
          "A equipe busca compreender o valor que a demanda desenvolvida irá proporcionar?",
        ],
        nivelIntermediario: [
          "(B) As tarefas oriundas de monitoramentos e/ou corretivas são priorizadas de maneira eficaz?",

          "Está claro para toda a equipe a lista de prioridades e é construída baseda no impacto e no valor que estas prioridades geram, incluindo issues relacionadas aos épicos, manutenções evolutivas e corretivas?",
          "Há critérios de aceitação (requisitos) e estes são conferidos pelo time após a conclusão de etapas do épico (por exemplo: história, demanda mãe, etc)? ",
          "O time considera que os critérios de aceitação (requisitos) são úteis e suficientes/coerentes com as suas necessidades?",
          "A equipe busca compreender e consegue mensurar o valor que a demanda desenvolvida irá proporcionar?",
          "A equipe se preocupa em verificar se o valor esperado foi atingido? (por exemplo: perguntando para o cliente, consulta de uso, conversando com o usuário, etc)",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Satisfação do cliente",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "As entregas do time estão atendendo as expectativas do cliente?",
          "O time possui conhecimento de negócio suficiente para desenvolver soluções aderentes às necessidades dos clientes?",
          "Todo o time compreende o cliente? (por exemplo: realização de sprint review com o cliente, apresentação do cliente pelo P.O, participação eventual em reuniões com o cliente, etc)",
          "A equipe se preocupa em manter as ferramentas de transparência claras e atualizadas?",
          "A equipe mostra as evoluções das funcionalidades para o cliente de acordo com a cadência estipulada pelo projeto? ",
        ],
        nivelIntermediario: [
          "(B) A equipe se preocupa em manter as ferramentas de transparência claras e atualizadas?",
          "(B)A equipe mostra as evoluções das funcionalidades para o cliente de acordo com a cadência estipulada pelo projeto? ",

          "O time avalia durante o processo se as expectivas do cliente estão sendo atendidas e toma ações para que elas sejam atendidas? ",
          "O time possui conhecimento de negócio suficiente para propor soluções aderentes às necessidades dos clientes?",
          "O time entende os objetivos estratégicos do cliente? (por exemplo: ter uma reflexão na equipe se eles sabem o motivo de desenvolver aquela tarefa, alinhamento com a estratégia do cliente, etc)",
          "A equipe acompanha os resultados da SCB e discutem como podem atuar para melhorar esses resultados?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Satisfação do usuário final",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time conhece e está trabalhando nas principais reclamações e problemas encontrados em produção? (por exemplo: tarefas vindas do suporte, etc)",
          "O time conhece e acompanha feedbacks do usuário com a cadência estipulada pelo projeto? (por exemplo: comentários da planilha de satisfação ou das lojas dos aplicativos, etc)",
          "O time conhece as necessidades dos diferentes perfis de usuários que utilizam o sistema? (personas)",
          "O Product Owner e o Design se relacionam frequentemente com o usuário?",
          "O time valida as propostas de soluções com os usuários?",
        ],
        nivelIntermediario: [
          "(B) O time conhece e está trabalhando nas principais reclamações e problemas encontrados em produção? (por exemplo: tarefas vindas do suporte, etc)",

          "O time prioriza ações e gera resultados no tempo adequado a partir do que encontra nos feedbacks do usuário?  (por exemplo: comentários da planilha de satisfação ou das lojas dos aplicativos)",
          "O time evita impactos negativos nos diferentes perfis de usuários que utilizam o sistema ao implementar uma nova solução?",
          "Todo o time se relaciona eventualmente com o usuário? (por exemplo: participa de entrevistas mesmo que como ouvinte, acompanha visitas aos usuários, etc)",
          "O time valida as funcionalidades em ambientes de produção controlados antes que sejam disponibilizadas para todos os usuários e consegue implementar melhorias antes da entrega final quando identificadas? (por exemplo: teste beta, piloto)",
          "O time gera métricas quantitativas baseadas no comportamento do usuário e consegue utilizá-las para propor melhorias? (por exemplo: geradas através grafana, google analytics, etc)",
          "O time valida as propostas de soluções com os usuários de modo a conseguir implementar melhorias antes da entrega da solução para produção?",
        ],
        nivelAvancado: [],
      },
    ],
  },
  {
    nome: "Pessoas sensacionais",
    categorias: [
      {
        nome: "Comunicação",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time percebe que a comunicação interna é eficiente e transparente?",
          "O time percebe que há interação e entrosamento entre todos?",
          "Todos conseguem dizer, de forma geral, o que todos estão fazendo?",
          "O time realiza feedback? (Reuniões individuais, informais)",
          "Quando algum integrante da equipe realiza ou é informado de alguma mudança que impacta nas atividades, todo o restante da equipe também recebe essa informação de forma rápida e fácil?",
          "O time é proativo ao comunicar mudanças que possam gerar impacto em outras equipes?",
          "O time se preocupa em manter uma comunicação assíncrona e remote first?",
        ],
        nivelIntermediario: [
          "(B) O time percebe que a comunicação interna é eficiente e transparente?",
          "(B) Quando algum integrante da equipe realiza ou é informado de alguma mudança que impacta nas atividades, toda o restante da equipe também recebe essa informação de forma rápida e fácil?",
          "(B) O time é proativo ao comunicar mudanças que possam gerar impacto em outras equipes?",
          "O time percebe que há interação e entrosamento entre todos os membros, proporcionando um ambiente confortável para sugerir melhorias mesmo que fora da sua área de atuação direta?",
          "Todos conseguem dizer, de forma geral, o que todos estão fazendo e se o andamento dessas tarefas está dentro do esperado?",
          "O time considera que o feedback realizado durante o ciclo são suficientes, contribuindo para o desenvolvimento pessoal e profissional de todos? (reuniões individuais, informais)",
          "Todos os integrantes da equipe, independente da modalidade de trabalho, participam e se sentem incluídos em momentos de tomada de decisão?",
          "Os integrantes da equipe que não conseguirem participar de reuniões e decisões recebem todas as informações necessárias (sobre problemas ou demais assuntos da equipe) de forma assíncrona?",
          "O time se sente confortável e realiza troca de feedback dentro da equipe?",
        ],
        nivelAvancado: [
          "(B) Quando algum integrante da equipe realiza ou é informado de alguma mudança que impacta nas atividades, toda o restante da equipe também recebe essa informação de forma rápida e fácil?",
          "(I) O time percebe que há interação e entrosamento entre todos os membros, proporcionando um ambiente confortável para sugerir melhorias mesmo que fora da sua área de atuação direta?",
          "(I) Todos conseguem dizer, de forma geral, o que todos estão fazendo e se o andamento dessas tarefas está dentro do esperado?",
          "(I) Todos os integrantes da equipe, independente da modalidade de trabalho, participam e se sentem incluídos em momentos de tomada de decisão?",
          "O time possui políticas de comunicação internas bem definidas? (por exemplo: slack ou discord, canal x serve para tal coisa, mensagem de atualização do branch)",
          "O time está constantemente ciente das opiniões dos outros membros sobre o seu trabalho?",
          "O time possui políticas de comunicação definidas em relação às mudanças que possam gerar impacto em outras equipes? (por exemplo: tarefas que afetam o mobile, refatoração de módulo que afete outra equipe, modificação de regra de negócio, etc)",
          "O time registra as principais decisões garantindo a gestão do conhecimento da equipe e proporcionando insumos para quem não pôde estar presente contribuir?",
          "O time se sente confortável e realiza troca de feedback quando pertinente com qualquer bridger?",
          "O time considera que os feedbacks trocados internamente e externamente acontecem de maneira clara, são completos e acontecem na hora certa? (exemplo: acontecem logo após a ocorrência e/ou possuem exemplos)",
        ],
      },
      {
        nome: "Motivação",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time se sente motivado?",
          "Há um sentimento de trabalho em equipe?",
          "O time se sente motivado com o propósito do seu trabalho/entrega?",
        ],
        nivelIntermediario: [
          "(B) O time se sente motivado?",
          "(B) Há um sentimento de trabalho em equipe?",

          "O time acompanha a motivação e consegue mensurá-la? (ex: índices de felicidade, acompanhamentos individuais, etc)",
          "A equipe consegue identificar e comunicar com clareza as razões específicas que os impulsionam?",
          "O time se sente motivado com o propósito do seu trabalho/entrega e consegue quantificar o impacto? (por exemplo:quantidade de usuários e cidadãos impactados, valor gerado, economia de recursos, otimização do tempo dos usuários, etc)",
          "Todos do time têm metas e objetivos individuais bem definidos que contribuem para a motivação geral?",
          "O time toma ações para manter a equipe motivada?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Autonomia e auto-organização",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time se auto-organiza para determinar a melhor maneira de conseguir atingir o propósito do seu trabalho?",
          "O time consegue remover ou buscar soluções para impedimentos que estão atrapalhando o desenvolvimento da sua demanda com apoio de agentes externos?",
          "Há autonomia suficiente dentro do time para utilizar ou propor diferentes metodologias para executar o seu trabalho da melhor forma?",
          "O time entende quando é necessário envolver a supervisão para resolver problemas dentro da equipe?",
        ],
        nivelIntermediario: [
          "(B) Há autonomia suficiente dentro do time para utilizar ou propor diferentes metodologias para executar o seu trabalho da melhor forma?",
          "O time é capaz de adaptar suas estratégias e metodologias de trabalho quando enfrenta mudanças significativas ou inesperadas no escopo ou nas prioridades do projeto?",
          "O time se auto-organiza para determinar a melhor maneira de conseguir atingir o propósito do seu trabalho mesmo quando desenvolvendo duas ou mais demandas em paralelo?",
          "O time consegue remover ou buscar soluções para impedimentos que estão atrapalhando o desenvolvimento da sua demanda com apoio de agentes externos minimizando o impacto no planejamento?",
          "O time tem autonomia para propor evoluções técnicas? (por exemplo: bibliotecas diferentes, novas tecnologias, soluções criativas para problemas comuns, etc)",
          "O time entende quando é necessário envolver a supervisão para resolver problemas dentro da equipe, envolvendo outras equipes e/ou o parceiro?",
          "O time tem autonomia para propor diferentes soluções para atingir os objetivos do produto priorizados em conjunto com o parceiro?",
          "O time tem autonomia para propor inovações nos processos de trabalho do Laboratório e dentro do projeto?",
        ],
        nivelAvancado: [
          "(B) Há autonomia suficiente dentro do time para utilizar ou propor diferentes metodologias para executar o seu trabalho da melhor forma?",
          "(I) O time é capaz de adaptar suas estratégias e metodologias de trabalho quando enfrenta mudanças significativas ou inesperadas no escopo ou nas prioridades do projeto?",
          "(I) O time se auto-organiza para determinar a melhor maneira de conseguir atingir o propósito do seu trabalho mesmo quando desenvolvendo duas ou mais demandas em paralelo?",
          "(I) O time consegue remover ou buscar soluções para impedimentos que estão atrapalhando o desenvolvimento da sua demanda com apoio de agentes externos minimizando o impacto no planejamento?",
          "(I) O time entende quando é necessário envolver a supervisão para resolver problemas dentro da equipe, envolvendo outras equipes e/ou o parceiro?",
          "O time tem autonomia e propõe evoluções técnicas? (por exemplo: bibliotecas diferentes, novas tecnologias, soluções criativas para problemas comuns, etc)",
          "O time possui autonomia e contribui para a visão de longo prazo do produto, propondo soluções inovadoras e alinhadas aos objetivos de negócio, mesmo que isso envolva mudanças significativas no escopo original?",
          "O time propõe inovações nos processos de trabalho do Laboratório e dentro do projeto?",
        ],
      },
      {
        nome: "Kaizen - Melhoria contínua",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "As reuniões de retrospectiva estão inseridas na cultura da equipe?",
          "O time identifica e trabalha oportunidades de melhoria nos seus processos?",
          "A equipe reserva tempo para executar atividades de melhoria contínua nos processos?",
          "Os planos de ação da b_agile e das retrospectivas são claros, possui responsável definido e acompanhados?",
        ],
        nivelIntermediario: [
          "(B) A equipe reserva tempo para executar atividades de melhoria contínua nos processos?",
          "A equipe considera que a reunião de retrospectiva é útil e eficiente, gerando ações de melhoria contínua na equipe?",
          "O time é totalmente autônomo na identificação e proativo ao trabalhar oportunidades de melhorias em seus processos?",
          "Os planos de ação da b_agile e das retrospectivas são claros, possuem responsável definido, possuem prazo e são acompanhados em toda sprint?",
          "Todos os membros da equipe possuem mentalidade de melhoria contínua em relação às próprias atividades?",
          "O time realiza as atividades de melhoria contínua da equipe independente de intervenção do SM? (por exemplo: retrospectiva e ações derivadas, planos de ação da b_agile, etc)",
          "A equipe consegue identificar quais melhorias são as mais valiosas? E faz isso de forma consistente, não apenas com base em achismos?",
          "A equipe executa os planos de ação ao longo do tempo, sem acúmulo de tarefas ao final dos prazos?",
        ],
        nivelAvancado: [
          "(B) A equipe reserva tempo para executar atividades de melhoria contínua nos processos?",
          "(I) A equipe considera que a reunião de retrospectiva é útil e eficiente, gerando ações de melhoria contínua na equipe?",
          "(I) O time é totalmente autônomo na identificação e proativo ao trabalhar oportunidades de melhorias em seus processos?",
          "(I) Os planos de ação da b_agile e das retrospectivas são claros, possuem responsável definido, possuem prazo e são acompanhados em toda sprint?",
          "(I) A equipe executa os planos de ação ao longo do tempo, sem acúmulo de tarefas ao final dos prazos?",
          "Todos os membros da equipe possuem mentalidade de melhoria contínua em relação aos processos da equipe, mesmo que não seja da sua responsabilidade direta?",
          "O time faz o planejamento e realiza as atividades de melhoria contínua da equipe independente de intervenção do SM? (por exemplo: retrospectiva e ações derivadas, planos de ação da b_agile, OKRs, etc)",
          "A equipe aborda a raiz dos problemas no planejamento das melhorias? Solucionando os problemas de maneira eficaz ao invés de soluções paliativas?",
          "A equipe consegue identificar quais melhorias são as mais valiosas utilizando métodos de priorização? (Exemplos: GUT, pareto, estratificação, histograma...)",
          "A equipe não considera como critério de sucesso a conclusão do plano de ação, mas sim, se as ações realizadas foram capazes de gerar o benefício desejado durante a criação do plano de ação.",
          "Após a conclusão do plano de ação, caso o problema inicial não esteja resolvido, a equipe traça um novo plano de ação, levando em conta os aprendizados da execução anterior?",
        ],
      },
      {
        nome: "Colaboração e Interdisciplinaridade",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Membros da equipe de desenvolvimento auxiliam as demais quando são identificados gargalos/impedimentos?",
          "Toda a equipe está disposta a realizar atividades simples de outras áreas durante o desenvolvimento da tarefa para facilitar o dia-a-dia? (por exemplo: pequenos ajustes no código ou na documentação, pequenos testes na etapa de desenvolvimento, retestar alterações no código feitas na etapa de code review, etc)",
          "A equipe consegue encaminhar atividades e resolver impedimentos mesmo na ausência do colaborador responsável?",
          "A equipe valida prazos e prioridades de desenvolvimento com equipes que podem ser impactadas por suas demandas, garantindo que ambos estejam alinhados antes de seguir com as entregas?",
        ],
        nivelIntermediario: [
          "(B) A equipe consegue encaminhar atividades e resolver impedimentos mesmo na ausência do colaborador responsável?",
          "Membros da equipe de desenvolvimento auxiliam as demais para evitar o surgimento de gargalos/impedimentos? (por exemplo: auxilia em testes exploratórios, auxilia em criação de ambientes, escrita de testes automatizados que reduzam a carga de trabalho dos testers, etc)",
          "Toda a equipe está disposta a realizar atividades de outras áreas durante o desenvolvimento da tarefa para facilitar o dia-a-dia? (por exemplo: pequenos ajustes no código ou na documentação, pequenos testes na etapa de desenvolvimento, retestar alterações no código feitas na etapa de code review, etc)",
          "A equipe mantém um processo de colaboração com demais equipes? (Ex: Membros da equipe oferecem apoio em dúvidas de testes, negócio, desenvolvimento, revisão de código, etc)",
          "A equipe colabora para alcançar os resultados chave do time e projeto? (por exemplo: KRs do time, objetivos da equipe, KRs do projeto, etc)",
          "A equipe possui processos mapeados que definem como ocorre a troca de responsabilidades entre diferentes papéis, promovendo e facilitando a colaboração? (por exemplo: processo para seguir quando um desenvolvedor realizar testes na etapa de QA)",
        ],
        nivelAvancado: [
          "(B) A equipe consegue encaminhar atividades e resolver impedimentos mesmo na ausência do colaborador responsável?",
          "(I) Toda a equipe está disposta a realizar atividades de outras áreas durante o desenvolvimento da tarefa para facilitar o dia-a-dia? (por exemplo: pequenos ajustes no código ou na documentação, pequenos testes na etapa de desenvolvimento, retestar alterações no código feitas na etapa de code review, etc)",
          "(I) A equipe mantém um processo de colaboração com demais equipes? (Ex: Membros da equipe oferecem apoio em dúvidas de testes, negócio, desenvolvimento, revisão de código, etc)",
          "(I) A equipe possui processos mapeados que definem como ocorre a troca de responsabilidades entre diferentes papéis, promovendo e facilitando a colaboração? (por exemplo: processo para seguir quando um desenvolvedor realizar testes na etapa de QA)",
          "Membros da equipe de desenvolvimento estão preparados para atuar de forma fluida em áreas que não são de sua especialidade, contribuindo diretamente em atividades de outros papéis sem que seja necessário direcionamento? (por exemplo: configuração de pipelines de CI/CD, testers realizando a otimização/revisão de código, criação/execução de cenários de testes, monitoramento de ambientes, decisões técnicas sobre as entregas, etc.)",
          "A equipe toma iniciativa para colaborar e sugerir inovações, contribuindo de forma ativa para a evolução do projeto e da organização?",
          "A equipe constrói soluções de forma colaborativa com outras equipes, compartilhando responsabilidades e garantindo a integração contínua? (Ex: A equipe se engaja em sprints conjuntas, integra tecnologias de diferentes times e ajusta processos colaborativos.)",
          "A equipe utiliza uma abordagem colaborativa para discutir o progresso, desafios e soluções, envolvendo todos os membros nas decisões? (Planejamento de prazos, gestão de riscos, discovery, etc)",
        ],
      },
    ],
  },
];
