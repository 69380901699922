import React from "react";
import { Button, HFlow, Icon, Tooltip } from "bold-ui";

const NavigationButtons = ({
  nextCategory,
  previousCategory,
  canGoNext,
  canGoPrevious,
}) => {
  return (
    <HFlow hSpacing={0.5}>
      <Tooltip text="Pergunta anterior">
        <Button
          size="small"
          onClick={previousCategory}
          disabled={!canGoPrevious}
        >
          <Icon icon="angleLeft" />
        </Button>
      </Tooltip>
      <Tooltip text="Próxima pergunta">
        <Button size="small" onClick={nextCategory} disabled={!canGoNext}>
          <Icon icon="angleRight" />
        </Button>
      </Tooltip>
    </HFlow>
  );
};

export default NavigationButtons;
