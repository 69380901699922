import React from "react";

function Sandbox() {
  return (
    <div style={{ flexGrow: 1, display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1, backgroundColor: "red" }}>
        {/* Content for the red div */}
      </div>
      <div style={{ flex: 1 }}>{/* Content for the second div */}</div>
    </div>
  );
}

export default Sandbox;
