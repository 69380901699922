import React, { useState, useEffect, useRef } from "react";
import { teamOptions } from "../../../assets/teams";

const divStyle = {
  width: "100%",
  height: "34px",
};

const selectStyle = {
  width: "300px",
  borderRadius: "2px",
  border: "1px solid #8F8FA2",
  height: "31px",
};

const TeamSelect = ({ onChange, admin, setFilteredOptions }) => {
  const filteredOptions = teamOptions.filter(
    (option) => option.admin === admin
  );
  const [selected, setSelected] = useState("");
  const hasSetInitialValue = useRef(false);

  useEffect(() => {
    const savedTeam = localStorage.getItem("selectedTeam");
    let initialTeam, initialLabel;

    if (
      savedTeam &&
      filteredOptions.some((option) => option.value === savedTeam)
    ) {
      // Se o valor salvo estiver nas opções permitidas, use-o
      initialTeam = savedTeam;
      initialLabel = teamOptions.find((opt) => opt.value === savedTeam)?.label;
    } else {
      // Caso contrário, use a primeira opção disponível e salve no localStorage
      initialTeam = filteredOptions.length > 0 ? filteredOptions[0].value : "";
      initialLabel = filteredOptions.length > 0 ? filteredOptions[0].label : "";
      localStorage.setItem("selectedTeam", initialTeam);
    }

    if (!hasSetInitialValue.current) {
      setSelected(initialLabel);
      if (onChange) {
        onChange(initialTeam);
      }
      hasSetInitialValue.current = true;
    }

    setFilteredOptions(filteredOptions.length > 0);
  }, [admin, onChange, filteredOptions, setFilteredOptions]);

  const handleChange = (e) => {
    const selectedLabel = e.target.value;
    const selectedValue = teamOptions.find(
      (opt) => opt.label === selectedLabel
    )?.value;

    setSelected(selectedLabel);
    localStorage.setItem("selectedTeam", selectedValue);

    if (onChange && selectedValue) {
      onChange(selectedValue);
    }
  };

  return (
    <div style={divStyle}>
      <select style={selectStyle} value={selected} onChange={handleChange}>
        {filteredOptions.map((option) => (
          <option key={option.value} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TeamSelect;
