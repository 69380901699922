import React, { useEffect, useCallback } from "react";
import { TextField, TextArea } from "bold-ui";

const valoresPermitidos = {
  basico: [
    "1.00",
    "1.25",
    "1.50",
    "1.75",
    "2.00",
    "2.25",
    "2.50",
    "2.75",
    "3.00",
    "3.25",
    "3.50",
    "3.75",
    "4.00",
    "4.25",
    "4.50",
    "4.75",
    "5.00",
  ],
  intermediario: [
    "5.25",
    "5.50",
    "5.75",
    "6.00",
    "6.25",
    "6.50",
    "6.75",
    "7.00",
    "7.25",
    "7.50",
    "7.75",
    "8.00",
  ],
  avancado: ["8.25", "8.50", "8.75", "9.00", "9.25", "9.50", "9.75", "10.00"],
};

const container = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const firstElement = {
  width: "25%",
  marginBottom: "16px",
};

const secondElement = {
  width: "41.66%",
};

function GradeCommentInput({
  grade,
  comment,
  handleGradeChange,
  handleCommentChange,
  setIsNotValid,
  level,
  isDisabled,
}) {
  // TODO: melhorar todo esse código
  const isValidValue = useCallback(
    (value) => {
      const normalizedValue = value.replace(",", ".");

      // Reject values that end with a comma or a dot
      if (normalizedValue.endsWith(".") || normalizedValue.endsWith(",")) {
        return false;
      }

      // Splitting the string into whole and decimal parts
      let [whole, decimal] = normalizedValue.split(".");

      // Ensure at least two characters after the decimal point
      if (decimal) {
        if (decimal.length === 1) {
          decimal += "0";
        }
      } else {
        decimal = "00";
      }

      // Construct the formatted value
      const formattedValue = `${whole}.${decimal}`;

      return valoresPermitidos[level]?.includes(formattedValue);
    },
    [level]
  );
  useEffect(() => {
    if (grade !== undefined && grade !== null) {
      const validity = isValidValue(grade);
      setIsNotValid(!validity);
    }
  }, [setIsNotValid, grade, level, isValidValue]);

  const handleValidatedGradeChange = (event) => {
    const newValue = event.target.value;
    const validity = isValidValue(newValue);
    setIsNotValid(!validity);
    handleGradeChange(event);
  };

  const validateGradeWhenCommentChange = (event) => {
    handleCommentChange(event);
    if (grade) {
      const validity = isValidValue(grade);
      setIsNotValid(!validity);
    }
  };

  const handleKeyPress = (event) => {
    if (!/[0-9.,]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div style={container}>
      <div style={firstElement}>
        <TextField
          label="Nota final"
          onChange={handleValidatedGradeChange}
          onKeyPress={handleKeyPress}
          required
          value={grade}
          clearable={false}
          maxLength="4"
          disabled={isDisabled}
        />
      </div>
      <div style={secondElement}>
        <TextArea
          style={{ height: "80px", paddingRight: "16px" }}
          label="Observações"
          maxLength="1500"
          value={comment}
          onChange={validateGradeWhenCommentChange}
          resize="none"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default GradeCommentInput;
