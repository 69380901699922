import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/bridgeLogo.svg";
import background from "../../assets/background.png";
import { GoogleLogin } from "react-google-login";

import { Cell, Grid, Heading, Text, HFlow, VFlow } from "bold-ui";
import { useUser } from "../../context/UserContext";

const fullScreenGridStyle = {
  height: "100%",
  width: "100%",
  position: "absolute",
  top: "0",
  margin: "0px",
};

const textSectionStyle = {
  padding: "0px",
};

const centeredTextContainerStyle = {
  paddingRight: "32px",
  paddingLeft: "32px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  "@media (max-width: 599px)": {
    alignItems: "center",
  },
};

const imageSectionStyle = {
  background: "#f0f0f5",
  padding: "0px",
  "@media (max-width: 599px)": {
    display: "none",
  },
};

const centeredImageContainerStyle = {
  paddingRight: "16px",
  paddingLeft: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

const backgroundStyle = {
  width: "100%",
  maxWidth: "694.05px",
  height: "auto",
};

// const logoBridgeStyle = {
//   height: "32px",
//   width: "auto",
// };

const clientId =
  "210690164422-avsevoi00ca205u5o48o9q3cmfkkqv7g.apps.googleusercontent.com";

function Login() {
  const navigate = useNavigate();
  const { setUserProfile, logout } = useUser();
  const logoutRef = useRef(logout);

  useEffect(() => {
    logoutRef.current();
  }, []);

  const responseGoogle = (response) => {
    if (response.profileObj) {
      const { name, email, imageUrl } = response.profileObj;
      setUserProfile({ name, email, imageUrl });

      const attemptedURL = localStorage.getItem("attemptedURL");
      if (attemptedURL) {
        localStorage.removeItem("attemptedURL");
        navigate(attemptedURL);
      } else {
        navigate("/create");
      }
    }
  };

  return (
    <Grid gap={0} style={fullScreenGridStyle}>
      <Cell sm={4} xs={12} style={textSectionStyle}>
        <VFlow style={centeredTextContainerStyle}>
          <HFlow alignItems="center">
            {/* <img style={logoBridgeStyle} src={logo} alt="Logo Bridge" /> */}
            <Heading
              color="normal"
              fontWeight="bold"
              level={1}
              style={{ margin: "0px" }}
            >
              b<span style={{ color: "#0069d0" }}>_</span>agile
            </Heading>
          </HFlow>
          <Text fontSize="1" fontWeight="initial">
            Olá! Por favor, faça o login com o Google para continuar
          </Text>
          <GoogleLogin
            clientId={clientId}
            buttonText="Login com Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </VFlow>
      </Cell>
      <Cell size={8} style={imageSectionStyle}>
        <div style={centeredImageContainerStyle}>
          <img style={backgroundStyle} src={background} alt="Background" />
        </div>
      </Cell>
    </Grid>
  );
}

export default Login;
