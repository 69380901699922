import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SmRoom from "./pages/sm-room/SmRoom";
import VotingRoom from "./pages/vote-room/VoteRoom";
import { UserProvider } from "./context/UserContext";
import { AdminProvider } from "./context/AdminContext";
import { useKeypressSequence } from "./hooks/useKeypressSequence";

import Login from "./pages/login/Login";
import { gapi } from "gapi-script";
import Sandbox from "./pages/sandbox/Sandbox";
import Header from "./components/Header";

const appStyle = {
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
};

const clientId =
  "210690164422-avsevoi00ca205u5o48o9q3cmfkkqv7g.apps.googleusercontent.com";

function App() {
  useKeypressSequence("legacy", () => {
    alert("A melhor equipe");
  });

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });
  return (
    <div style={appStyle}>
      <BrowserRouter>
        <UserProvider>
          <AdminProvider>
            <Header></Header>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/create" element={<SmRoom />} />
              <Route path="/room/:room" element={<VotingRoom />} />
              <Route path="/sandbox" element={<Sandbox />} />
            </Routes>
          </AdminProvider>
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
