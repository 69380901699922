export const teamOptions = [
  {
    label: "Candy Crush",
    value: "candy-crush",
    admin: "gabriel.campos@bridge.ufsc.br",
  },
  {
    label: "Crash",
    value: "crash",
    admin: "gabriel.campos@bridge.ufsc.br",
  },
  {
    label: "DeLorean",
    value: "delorean",
    admin: "jonathan.martins@bridge.ufsc.br",
  },
  {
    label: "Eldorado",
    value: "eldorado",
    admin: "fabricio.matos@bridge.ufsc.br",
  },
  {
    label: "Falcon",
    value: "falcon",
    admin: "mirian@bridge.ufsc.br",
  },
  {
    label: "Foundation",
    value: "foundation",
    admin: "everton@bridge.ufsc.br",
  },
  {
    label: "Fruit Ninja",
    value: "fruit-ninja",
    admin: "gabriel.campos@bridge.ufsc.br",
  },
  {
    label: "Legacy",
    value: "legacy",
    admin: "gilmardouglas@bridge.ufsc.br",
  },
  {
    label: "NP Completo",
    value: "np-completo",
    admin: "everton@bridge.ufsc.br",
  },
  {
    label: "Codebenders",
    value: "codebenders",
    admin: "jonathan.martins@bridge.ufsc.br",
  },
  {
    label: "RNG",
    value: "rng",
    admin: "jonathan.martins@bridge.ufsc.br",
  },
  {
    label: "Royal Flush",
    value: "royal-flush",
    admin: "jessica.marques@bridge.ufsc.br",
  },
  {
    label: "Snake",
    value: "snake",
    admin: "jessica.marques@bridge.ufsc.br",
  },
  {
    label: "Supernova",
    value: "supernova",
    admin: "nathanwerlich@bridge.ufsc.br",
  },
  {
    label: "Sirius",
    value: "sirius",
    admin: "nathanwerlich@bridge.ufsc.br",
  },
  {
    label: "Acesso Ricardo",
    value: "acesso-ricardo",
    admin: "ricardoeyng10@gmail.com",
  },
  {
    label: "Acesso Ricardo 2",
    value: "acesso-ricardo-2",
    admin: "ricardoeyng10@gmail.com",
  },
  {
    label: "Acesso Will",
    value: "acesso-will",
    admin: "willian@bridge.ufsc.br",
  },
  {
    label: "Acesso Will 2",
    value: "acesso-will-2",
    admin: "willian@bridge.ufsc.br",
  },
  {
    label: "Acesso Aline",
    value: "acesso-aline",
    admin: "aline@bridge.ufsc.br",
  },
  {
    label: "Acesso Amanda",
    value: "acesso-amanda",
    admin: "amanda.fogaca@bridge.ufsc.br",
  },
  {
    label: "Acesso Gabriel",
    value: "acesso-gabriel",
    admin: "gabriel@bridge.ufsc.br",
  },
];
