import React from "react";
import { Text } from "bold-ui";
import { Nivel } from "../../../assets/data";

const LevelTag = ({ value }) => {
  const levelOptions = [
    {
      label: "básico",
      value: Nivel.BASICO,
    },
    {
      label: "intermediário",
      value: Nivel.INTERMEDIARIO,
    },
    {
      label: "avançado",
      value: Nivel.AVANCADO,
    },
  ];

  const selectedOption = levelOptions.find((opt) => opt.value === value);
  const selectedLabel = selectedOption.label;

  const getColor = (value) => {
    switch (value) {
      case Nivel.BASICO:
        return "#498FFF";
      case Nivel.INTERMEDIARIO:
        return "#E76F00";
      case Nivel.AVANCADO:
        return "#8600B6";
      default:
        return "black";
    }
  };

  return (
    <div
      style={{
        paddingTop: "1px",
        paddingBottom: "1px",
        paddingLeft: "9px",
        paddingRight: "9px",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: getColor(value),
        color: "#fff",
        backgroundColor: getColor(value),
        fontWeight: "bold",
        display: "inline-flex",
      }}
    >
      <Text style={{ color: "#fff" }}>Nível {selectedLabel}</Text>
    </div>
  );
};

export default LevelTag;
