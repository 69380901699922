import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import socket from "../../socket";
import { useUser } from "../../context/UserContext";
import { useAuth } from "../../hooks/useAuth";

import { bAgile, getPerguntasByNivel, Nivel } from "../../assets/data";
import {
  HFlow,
  Heading,
  VFlow,
  Text,
  Grid,
  Cell,
  Icon,
  Tooltip,
  Button,
  Alert,
} from "bold-ui";
import LevelSelect from "../../components/LevelSelect";
import ErrorRoom from "../../components/ErrorRoom";
import AppliedCategory from "../../components/AppliedCategory";
import { teamOptions } from "../../assets/teams";

import TeamSelect from "./components/TeamSelect";

const cardStyle = {
  backgroundColor: "#f0f0f5",
  padding: "10px 16px",
  marginBottom: "12px",
  border: "1px solid #8F8FA2",
  justifyContent: "space-between",
  borderRadius: "4px",
};

function SmRoom() {
  // room que vai ser definida pelo TeamSelect
  const [room, setRoom] = useState("");
  // data que é os dados da bAgile configurados, mas vou ter que receber o que tem armazenado no banco
  const [data, setData] = useState(bAgile);
  // dados de quem está logado
  const { userProfile } = useUser();
  // variavel que decide se o alerta de link copiado aparece
  const [copied, setCopied] = useState(false);

  const [hasOptions, setHasOptions] = useState(true);

  // navigate utilizado para redirecionar para a pagina da aplicação
  const navigate = useNavigate();
  // verifica se quem está na sala está autenticado, evoluir isso pra verificar se faz parte dos emails de SM
  const { checkAuthentication: checkAuth } = useAuth();

  const checkAuthentication = useCallback(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    if (room) {
      // Emita o evento de socket em vez de fazer a chamada HTTP
      socket.emit("getRoomData", room);
    }
  }, [room]);

  const populateItemStructure = useCallback((dataFromDb) => {
    const clonedData = JSON.parse(JSON.stringify(bAgile));

    if (!dataFromDb || dataFromDb.length === 0) {
      setData(bAgile);
      return;
    }

    dataFromDb.forEach((dbEntry) => {
      const { categoryIndex, itemIndex } = dbEntry.index;
      if (
        clonedData[itemIndex] &&
        clonedData[itemIndex].categorias[categoryIndex]
      ) {
        clonedData[itemIndex].categorias[categoryIndex].comentario =
          dbEntry.comment || null;
        clonedData[itemIndex].categorias[categoryIndex].nota =
          dbEntry.grade || null;
        clonedData[itemIndex].categorias[categoryIndex].nivelSelecionado =
          dbEntry.level || Nivel.BASICO;
      }
    });

    setData(clonedData);
  }, []);

  useEffect(() => {
    socket.on("roomDataResponse", (dataFromServer) => {
      populateItemStructure(dataFromServer); // Atualizando a estrutura de data com os dados recebidos
    });

    return () => {
      socket.off("roomDataResponse");
    };
  }, [populateItemStructure]);

  const deleteAll = () => {
    const team = teamOptions.find((option) => option.value === room);

    if (
      window.confirm(
        `Todas as notas e comentários da equipe ${
          team ? team.label : ""
        } serão apagados. Você tem certeza que gostaria de reiniciar a aplicação?`
      )
    ) {
      socket.emit("deleteAll", room);
    }
  };

  // metodo que cria a sala, definindo o nome da sala (vindo do TeamSelect), dados do b_agile e email do admin
  const createRoom = (currentItemIndex, currentCategoryIndex) => {
    const email = userProfile.email;

    socket.emit("createOrUpdateRoom", {
      room,
      data,
      email,
      currentItemIndex,
      currentCategoryIndex,
    });
    navigate(`/room/${room}`);
  };

  // muda o nivel da categoria e salva no data
  const handleNivelCategoryChange = (itemIndex, catIndex, newValue) => {
    setData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData));
      newData[itemIndex].categorias[catIndex].nivelSelecionado = newValue;
      return newData;
    });

    socket.emit("storeLevel", {
      room: room,
      itemIndex: itemIndex,
      categoryIndex: catIndex,
      level: newValue,
    });
  };

  const handleCopyClick = (room) => {
    const baseURL = window.location.origin;
    const fullURL = `${baseURL}/room/${room}`;
    navigator.clipboard.writeText(fullURL).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Remove o status de "copiado" após 2 segundos
    });
  };

  // (TODO): melhorar forma de exportar arquivos
  const escapeCSVField = (field) => {
    if (field === null) return "-";
    const escapedField = field.replace(/"/g, '""'); // Duplica qualquer aspa dupla.
    return `"${escapedField}"`; // Envolve o campo em aspas duplas.
  };

  const convertDataToCSV = (data) => {
    let csvContent = "Categoria,Nota,Comentário\n";

    data.forEach((item) => {
      item.categorias.forEach((categoria) => {
        const nome = escapeCSVField(categoria.nome);
        const nota = escapeCSVField(categoria.nota);
        const comentario = escapeCSVField(categoria.comentario);

        csvContent += `${nome},${nota},${comentario}\n`;
      });
    });

    return csvContent;
  };

  const downloadCSV = (data) => {
    const csvContent = convertDataToCSV(data);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}`; // +1 porque getMonth() começa de 0
    const fileName = `${room}_${formattedDate}.csv`;

    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  return hasOptions ? (
    <Grid
      gap={0}
      style={{ paddingRight: "32px", paddingLeft: "32px", marginTop: "32px" }}
    >
      <Cell size={6}>
        <Heading
          color="normal"
          fontWeight="bold"
          level={1}
          style={{ margin: "0px", color: "#002451" }}
        >
          Aplicação da b_agile
        </Heading>
      </Cell>
      <Cell size={6}>
        <HFlow justifyContent="end">
          {/* TODO: usuario fazer download dos dados do banco da sala selecionada em um .txt */}
          <Button kind="primary" size="small" onClick={() => downloadCSV(data)}>
            Exportar resultados
          </Button>
          <Button kind="danger" size="small" onClick={() => deleteAll()}>
            Reiniciar aplicação
          </Button>
        </HFlow>
      </Cell>
      <Cell size={7}>
        <Text fontSize="1" style={{ color: "#002451" }}>
          Selecione sua equipe para iniciar a aplicação da b_agile, na sequência
          configure o nível de cada categoria. Ao finalizar você pode exportar
          seus resultados para incluir na planilha.
        </Text>
      </Cell>
      <Cell size={6}>
        <Text fontWeight="bold">Equipe</Text>
        <HFlow>
          {/* // TODO : deixar select bonito */}
          <TeamSelect
            admin={userProfile.email}
            onChange={(newValue) => setRoom(newValue)}
            setFilteredOptions={setHasOptions}
          ></TeamSelect>
          <Button
            style={{ height: "32px" }}
            size="small"
            onClick={() => handleCopyClick(room)}
          >
            <Icon icon="copyOutline"></Icon>
            Copiar link
          </Button>
          {copied && (
            <Alert inline type="success">
              Link copiado com sucesso
            </Alert>
          )}
        </HFlow>
      </Cell>
      <Cell size={12}>
        {data.map((item, itemIndex) => (
          <React.Fragment key={itemIndex}>
            <Heading
              key={itemIndex}
              level={4}
              style={{
                marginBottom: "16px",
                marginTop: "24px",
                color: "#002451",
              }}
            >
              {item.nome}
            </Heading>
            {item.categorias.map((categoria, catIndex) => (
              <HFlow
                hSpacing={0}
                style={cardStyle}
                key={itemIndex + "" + catIndex}
              >
                <VFlow vSpacing={0} style={{ minWidth: "330px" }}>
                  <Text fontWeight="bold">{categoria.nome}</Text>
                  <Text>
                    {getPerguntasByNivel(categoria).length + " perguntas"}
                  </Text>
                </VFlow>
                <div
                  style={{
                    minWidth: "163px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LevelSelect
                    currentNivel={categoria.nivelSelecionado}
                    onChange={(newValue) =>
                      handleNivelCategoryChange(itemIndex, catIndex, newValue)
                    }
                    hideAdvanced={categoria.nivelAvancado.length === 0}
                  />
                </div>

                <HFlow
                  hSpacing={0.5}
                  alignItems="center"
                  style={{ minWidth: "70px" }}
                >
                  <Text fontWeight="bold">Nota: </Text>
                  <Text> {categoria.nota ? categoria.nota : "-"} </Text>
                </HFlow>
                <div
                  style={{
                    minWidth: "162px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    {categoria.nota ? <AppliedCategory></AppliedCategory> : ""}
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip text="Aplicar categoria">
                    <Button
                      size="small"
                      skin="ghost"
                      style={{ height: "32px" }}
                      onClick={() => createRoom(itemIndex, catIndex)}
                    >
                      <Icon icon="arrowRight" />
                    </Button>
                  </Tooltip>
                </div>
              </HFlow>
            ))}
          </React.Fragment>
        ))}
      </Cell>
    </Grid>
  ) : (
    <ErrorRoom
      title="Você não possui acesso de Scrum Master"
      paragraph="Peça ao seu Scrum Master o link correto ou solicite acesso ao responsável pela aplicação"
    ></ErrorRoom>
  );
}
export default SmRoom;
